import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faCheck,
  faPhone,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { OwnerPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';

interface PageProps {
  data: OwnerPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="飼い主の皆様へ"
      description={`ご予約の方法はこちらになります。TEL${
        data.site?.siteMetadata?.tel ?? ''
      }。専用駐車場2台あり`}
      keywords="飼い主の皆様へ"
    />
    <article id="clinical-examination" css={layout}>
      <h2>飼い主の皆様へ</h2>
      <div className="skew-border"></div>
      <section>
        <h3>〇がん・腫瘍のサイン</h3>
        <p>
          近年犬と猫の死因の第1位は「悪性腫瘍・がん」であり、これは我々人間と同様です。
        </p>
        <p>
          以下のような症状が認められる場合、原因が悪性腫瘍の可能性もあるため注意が必要です。お気軽にお問い合わせください。
        </p>
        <ul>
          <li>・体重が減ってきた</li>
          <li>・食欲がない</li>
          <li>・おやつしか食べない</li>
          <li>・なんだか元気がない</li>
          <li>・いつもより疲れやすい</li>
          <li>・鼻血が出る</li>
          <li>・睡眠時にいびきが多くなってきた</li>
          <li>・飲水量と尿量がとても増えてきた</li>
          <li>・おなかが張ってきた</li>
        </ul>
        <p className="red">
          「しこり」だけではなくこのような症状にも注意が必要です
        </p>
      </section>
      <section>
        <h3>診療の流れ</h3>
        <div className="flow-container">
          <div className="flow-container-items">
            <p>
              <FontAwesomeIcon icon={faCheck} color={'#496db7'} />
              ご予約はインターネットまたはお電話で
            </p>
            <div>
              <Link
                to="https://step.petlife.asia/petreserve/subjectlist/index/cid/x4841964?SITE_CODE=hp"
                target="_blank"
                css={button}
              >
                飼い主様用ネット予約
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </Link>
            </div>
            <p className="tel">
              <FontAwesomeIcon icon={faPhone} color={'#496db7'} />
              &nbsp;TEL 03-6284-0450
            </p>
            <p>
              ※当院は<span className="red">予約優先制</span>
              です。予約無しでの診察も可能ですが、お待たせしてしまう可能性があるため、なるべくお電話でのご予約をお願いいたします。
            </p>
          </div>
          <div className="flow-container-items">
            <FontAwesomeIcon icon={faArrowDown} size={'3x'} color={'#496db7'} />
          </div>
          <div className="flow-container-items">
            <span className="flow">初診</span>
            <ul>
              <li>
                ・カルテ作成のため、初診の方は予約時間の10分前にお越しください。
              </li>
              <li>
                ・病院入口に<span className="red">専用駐車場が2台</span>
                ございます。駐車場が満車の場合には近隣のコインパーキングをご利用ください。
              </li>
              <li>
                ・かかりつけ動物病院からのご紹介、セカンドオピニオンの場合には
                <span className="red">
                  現在服用中のお薬、検査結果などをお持ちください。
                </span>
              </li>
              <li>
                ・当日、
                <span className="red">
                  全身麻酔や鎮静での手術、検査を予定している場合
                </span>
                <br />
                食事→前日の夜10時まで。それ以降は絶食
                <br />
                お水→当日の朝8時まで。以上の管理をお願いいたします。
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <h3>診療費について</h3>
        <ul className="table-container">
          <li className="table-container-items">
            <span className="col1">・初診料</span>
            <span className="col2">3,000円</span>
            <span className="col3">診療時間が1時間以上の場合には5,000円</span>
          </li>
          <li className="table-container-items">
            <span className="col1">・セカンドオピニオン</span>
            <span className="col2">5,000円</span>
            <span className="col3"></span>
          </li>
          <li className="table-container-items">
            <span className="col1">・各種検査</span>
            <span className="col2">合計10,000円〜30,000円</span>
            <span className="col3"></span>
          </li>
        </ul>
        <p>2023年4月現在</p>
      </section>
    </article>
  </Layout>
);
export default Page;

const layout = css`
  @media (max-width: 560px) {
    padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }
`;

const button = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  padding: 1em 2em;
  width: 300px;
  color: #024387;
  font-size: 18px;
  font-weight: 700;
  background-color: #cccccc;
  box-shadow: 0 5px 0 #aaaaaa;
  transition: 0.3s;

  &:hover {
    transform: translateY(3px);
    text-decoration: none;
    box-shadow: 0 2px 0 #aaaaaa;
  }

  @media (max-width: 560px) {
    width: 50vw;
    font-size: 14px;
  }
`;

export const query = graphql`
  query OwnerPage {
    site {
      siteMetadata {
        title
        tel
      }
    }
  }
`;
